exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-kancelaria-jsx": () => import("./../../../src/templates/kancelaria.jsx" /* webpackChunkName: "component---src-templates-kancelaria-jsx" */),
  "component---src-templates-kontakt-jsx": () => import("./../../../src/templates/kontakt.jsx" /* webpackChunkName: "component---src-templates-kontakt-jsx" */),
  "component---src-templates-mapa-strony-jsx": () => import("./../../../src/templates/mapa-strony.jsx" /* webpackChunkName: "component---src-templates-mapa-strony-jsx" */),
  "component---src-templates-polityka-prywatnosci-jsx": () => import("./../../../src/templates/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-templates-polityka-prywatnosci-jsx" */),
  "component---src-templates-specializacja-archiwum-jsx": () => import("./../../../src/templates/specializacja-archiwum.jsx" /* webpackChunkName: "component---src-templates-specializacja-archiwum-jsx" */),
  "component---src-templates-specializacja-jsx": () => import("./../../../src/templates/specializacja.jsx" /* webpackChunkName: "component---src-templates-specializacja-jsx" */),
  "component---src-templates-wspolpraca-jsx": () => import("./../../../src/templates/wspolpraca.jsx" /* webpackChunkName: "component---src-templates-wspolpraca-jsx" */)
}

